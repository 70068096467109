@use 'styles/variables' as var;
@use 'styles/functions' as fn;

.section {
  padding: fn.spacing(6) 0 0;
}

.disclosure-mobile {
  margin-bottom: fn.spacing(6.5);
}

.disclosure-right {
  margin-top: 8px;
  text-align: right;
}

.pd-item-subimage {
  object-fit: contain;
  object-position: center top;
  max-height: inherit !important;
  min-height: inherit !important;
  height: auto;
}

.disclosure-left {
  margin-top: 8px;
  text-align: left;
}

.disclosure-link {
  color: #42546b;
  text-decoration: none;
}

.disclosure-link:hover {
  color: #42546b;
  text-decoration: underline;
}

.pd-item,
.pd-item-secondary {
  background: #fff;
  align-items: center;
  padding: 0 16px;
  justify-content: center;
}

[data-inverse='false'].pd-item-secondary {
  background: var.$color-blue-25;
}

[data-inverse='true'].pd-item-secondary {
  background: #fff;
}

[data-inverse='true'].pd-item {
  background: var.$color-blue-25;
}

.pd-item-eyebrow {
  text-align: left;
  padding-bottom: 0;
}

.pd-item-list {
  padding: 0;
  margin: 0;
}

.pd-item-panel-left {
  padding: fn.spacing(6) 0 0 0;
  max-width: 500px;
}

.pd-item-panel-right {
  padding: 0 0 fn.spacing(6) 0;
  max-width: 500px;
}

[data-variation='variation1'] .pd-item-panel-left {
  padding: 16px 0;
}

[data-variation='variation1'] .pd-item-panel-right {
  padding: 16px 0;
}

.pd-item-brand,
.pd-item-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  max-width: 420px;
  margin: 0 auto;
  position: relative;
}

.pd-item-brand {
  gap: 0;
}

.pd-item-image {
  border-radius: 8px;
  height: 100%;
  width: 100%;
}

.pd-item-card-image {
  position: relative;
  margin-top: -70px;
}

[data-variation='variation1'] .pd-item-card-image {
  padding: 0 fn.spacing(1.25);
  margin-top: fn.spacing(-4.375);
}

.card-link {
  padding: 0 !important;
}

[data-variation='variation1'] .card-link {
  padding-left: 0;

  p {
    width: 100%;
    text-align: left;
  }
}

[data-variation='variation1'].pd-item,
[data-variation='variation1'].pd-item-secondary {
  padding: 32px 16px;
}

.pd-item-card-image-disclosure {
  position: relative;
  margin-top: -70px;
}

.pd-item-list-text {
  line-height: 0;
}

.sticky-tabs {
  margin-top: 32px;
}

.pd-eye-brow {
  letter-spacing: 0.25em;
  padding-bottom: 8px;
}

.pd-item-no-secondary-image .pd-item-panel-left {
  padding: 32px 0;
}

.button {
  align-self: center;
  width: 100%;
}

.app-store-btns-container {
  display: flex;
  justify-content: center;
}

[data-component='GraphicCardList'] .pd-item-subimage-html {
  min-width: 250px;
  margin: 30px;
}

[data-component='GraphicCommentBubble'] .pd-item-subimage-html {
  min-width: 250px;
}

.pd-help-text {
  color: #42546b;
  margin-top: 16px;
}

.highlight-box {
  padding: 8px;
  border-radius: 8px;
  background: var.$color-blue-100;
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  color: var.$color-neutral-900;
}

@media screen and (min-width: 1024px) {
  .button {
    width: fit-content;
    align-self: start;
  }

  .section {
    padding: 64px 0 0;
  }

  .pd-item,
  .pd-item-secondary {
    padding: 64px 0 0;
    justify-content: inherit;
    min-height: fn.spacing(50);
  }

  .pd-item-no-secondary-image {
    padding: 64px 0;
  }

  .pd-item-card-image {
    margin-top: -200px;
    width: 130%;
    right: -70px;
  }

  .pd-item-card-image-disclosure {
    margin-top: -224px;
    width: 130%;
    right: -70px;
  }

  .pd-item-secondary .pd-item-card-image {
    left: -170px;
  }

  .pd-item-secondary .pd-item-card-image-disclosure {
    left: -170px;
  }

  .pd-item-panel-left,
  .pd-item-panel-right {
    padding: 0;
    max-width: initial;
  }

  .pd-item-panel-left {
    .pd-item-brand {
      padding-right: 50px;
    }
  }

  .pd-item-secondary .pd-item-panel-left {
    .pd-item-brand {
      padding-left: 50px;
      padding-right: 0;
    }
  }

  .pd-eye-brow {
    padding-bottom: 16px;
  }

  [data-variation='variation1'] .pd-item-card-image {
    margin-top: fn.spacing(-9);
    width: 100%;
    right: fn.spacing(-20.625);
  }

  [data-variation='variation1'].pd-item-secondary .pd-item-card-image {
    left: fn.spacing(-19);
  }

  .pd-item[data-variation='variation1'],
  .pd-item-secondary[data-variation='variation1'] {
    padding: 64px 0;
  }

  .app-store-btns-container {
    display: flex;
    justify-content: flex-start;
  }

  [data-component='GraphicCardList'] .pd-item-subimage-html {
    min-width: 350px;
    width: 350px;
    margin: 55px;
  }

  [data-component='GraphicCommentBubble'] .pd-item-subimage-html {
    min-width: 300px;
    width: 300px;
  }
}

@media screen and (min-width: var.$breakpoint-xl) {
  .pd-item-panel-left {
    .pd-item-brand {
      padding-left: 0;
      padding-right: 0;
    }
  }

  .pd-item-secondary .pd-item-panel-left {
    .pd-item-brand {
      padding-left: 0;
      padding-right: 0;
    }
  }

  [data-component='GraphicCommentBubble'] .pd-item-subimage-html {
    min-width: 350px;
    width: 350px;
  }
}

.backgroud-color-primary-highlight-blue2 {
  background: var.$color-blue-25;
}

.backgroud-color-neutral-0 {
  background: #fff;
}
